import React from 'react';
import { VerifyCertificate } from '../components/Certificate/Verify';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { Router } from '@reach/router';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';

export default function VerifyPage() {
  return (
    <PublicLayout>
      <Router basepath="/verify">
        <VerifyCertificate path="/:id" />
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
