import React, { useEffect } from 'react';
import { useParams } from '@reach/router';
import SeoHeader from '../Shared/SeoHeader';
import { Wrapper } from '../Shared/Wrapper';
import { RouteComponentProps } from '@reach/router';
import { useVerifyCertificateLazyQuery } from '../../graphql/__types';
import { CardWrapper } from '../Shared/Wrapper/CardWrapper';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { WebinarDetailLoader } from '../Webinar/WebinarLoader/WebinarDetailLoader';
import dayjs from 'dayjs';

export function VerifyCertificate(_: RouteComponentProps) {
  const { id } = useParams();
  const [verifyCertificate, { data, loading }] =
    useVerifyCertificateLazyQuery();

  const fetchData = async () => {
    verifyCertificate({
      variables: {
        verifyCertificateId: id,
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <SeoHeader title="Verify Certificate" />

      <section className="w-full md:w-6/12 mx-auto my-10">
        <CardWrapper>
          {loading ? (
            <WebinarDetailLoader />
          ) : !!data && !!data.verifyCertificate ? (
            !data.verifyCertificate.isValid ? (
              <section className="flex flex-col items-center justify-center">
                <XIcon className="w-28 h-28 text-primary-1" />
                <h1 className="text-2xl font-bold uppercase">
                  Invalid Certificate
                </h1>
                <p className="text-sm text-center w-full md:w-8/12 mx-auto mt-5">
                  It seems that this certificate is not valid, if you think that
                  this is an error on our side you may contact us at{' '}
                  <a
                    href="mailto:mail@region.dost.gov.ph"
                    className="font-bold text-primary-1"
                  >
                    mail@region.dost.gov.ph
                  </a>
                </p>
              </section>
            ) : (
              <section className="flex flex-col items-center justify-center">
                <CheckIcon className="w-28 h-28 text-green-500" />
                <h1 className="text-2xl font-bold uppercase">
                  Valid Certificate
                </h1>

                <section className="text-center w-full md:w-8/12 mx-auto">
                  <section className="my-5">
                    <p className="font-bold">Attendee</p>
                    <p className="text-center">
                      {!!data.verifyCertificate.userName &&
                        data.verifyCertificate.userName}
                    </p>
                  </section>

                  <section className="my-5">
                    <p className="font-bold">Topic</p>
                    <p className="text-center">
                      {!!data.verifyCertificate.webinarTopic &&
                        data.verifyCertificate.webinarTopic}
                    </p>
                  </section>

                  <section className="my-5">
                    <p className="font-bold">Description</p>
                    <p className="text-center">
                      {!!data.verifyCertificate.webinarDescription &&
                        data.verifyCertificate.webinarDescription}
                    </p>
                  </section>

                  <section className="my-5">
                    <p className="font-bold">Date/Time</p>
                    <p className="text-center">
                      {!!data.verifyCertificate.webinarDate &&
                        dayjs(data.verifyCertificate.webinarDate).format(
                          'MMMM DD, YYYY',
                        )}
                    </p>
                  </section>
                </section>

                <p className="text-sm text-center w-full md:w-8/12 mx-auto mt-5">
                  If you have questions or concern you may contact us at{' '}
                  <a
                    href="mailto:mail@region.dost.gov.ph"
                    className="font-bold text-primary-2"
                  >
                    mail@region.dost.gov.ph
                  </a>
                </p>
              </section>
            )
          ) : (
            <></>
          )}
        </CardWrapper>
      </section>
    </Wrapper>
  );
}
