import React from 'react';

export function WebinarDetailLoader() {
  return (
    <section className="w-full">
      <section className="animate-pulse">
        <section className="container mx-auto">
          <section className="flex flex-wrap flex-row">
            <section className="my-1 px-1 w-full">
              <article className="h-full">
                <section className="bg-gray-300 h-5 w-3/12 rounded mb-2 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-5 w-3/12 rounded mb-7 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-3 w-7/12 rounded mb-2 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-3 w-7/12 rounded mb-5 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-3 w-7/12 rounded mb-7 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-3 w-4/12 rounded mb-2 mx-auto lg:mx-0" />
                <section className="bg-gray-300 h-3 w-4/12 rounded mb-5 mx-auto lg:mx-0" />
              </article>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
